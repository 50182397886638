export const images = [
  "homeImageForth.jpg",

  "homeImageThird.jpeg",

  "homeImageFirst.jpeg",
  "homeImageFifth.jpeg"
];
export const aboutImages =[
  "aboutImage2.jpeg",
  "aboutImage3.jpeg",
  "aboutImage.jpeg",
  

  
]

export const services = [
  "Civil Litigation: We handle all types of civil cases, including injunctions, suits, appeals, and writ petitions.",
  "Criminal Litigation: Our experts represent clients in criminal cases, including bail, trial, and appeal matters.",
  "Commercial Litigation: We handle commercial disputes, including contract disputes, shareholder disputes, and arbitration matters.",
  "Property Disputes: Our team resolves property-related disputes, including inheritance, partition, and possession matters.",
  "Family Law: We provide legal assistance in matrimonial disputes, child custody, divorce, and maintenance cases.",
  "Wills & Probate: Our experts guide clients in will drafting, probate, and estate planning.",
  "Contract Drafting: We draft and review contracts for individuals and businesses, including employment contracts, service agreements, and business partnerships.",
  "Writ Petitions: Our team files and argues writ petitions in High Courts and the Supreme Court.",
  "Rent Control Cases: We handle rent control matters, including eviction, rent revision, and tenancy disputes.",
  "Legal Advisory: Our team provides legal advice on various matters, including business setup, compliance, and regulatory issues.",
  "Arbitration: We represent clients in arbitration proceedings and mediations.",
  "Taxation: Our experts handle tax planning, return filing, and tax litigation matters.",
  "Co-operative Society Matters: We assist clients with co-operative society registrations, disputes, and compliance.",
  "Real Estate Transactions: Our team facilitates property purchases, sales, and leasing.",
  "Intellectual Property Rights: We protect clients' intellectual property rights, including trademarks, copyrights, and patents.",
  "Consumer Cases: Our experts represent clients in consumer forums and commissions.",
  "NRI Legal Services: We provide legal assistance to non-resident Indians in various matters.",
];

export const linkPath = ["about", "about/expertise","about/partners"];
